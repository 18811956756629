// libs
import React, {forwardRef, useContext} from 'react'
// components
import { ContextStore } from '../../providers/context';
// css 
// config & functions

const Select = forwardRef(({config}, ref) => {
    // context variable
	const globalState = useContext(ContextStore);
	const {dispatch} = globalState;
	// context variable --//

    const contextValue = globalState.state[config.context];
    const defaultVal = contextValue && contextValue.hasOwnProperty(config.name) ? contextValue[config.name] : '';

    const PrintOption = () => {
        if(config.value && Array.isArray(config.value) && config.value.length > 0) {
            return config.value.map(val => {
                return <option key={Math.random()} value={val}>{val}</option>
            })
        } else {
            return null;
        }
    }

    return <div className={`col-md-${config.column} mb-3`}>
        <label className="form-label fw-bold fs-14rem">{config.label} {(config.rules.required)?<span className="text-danger">*</span>:null}</label>
        <select className="form-select" aria-label={config.name} name={config.name} defaultValue={defaultVal} ref={(ele) => ref.current[config.index] = ele}>
            <PrintOption />
        </select>
        {config.errorFields !== "" ? <div className='text-danger fs-13rem'>{config.errorFields}</div>:null}
    </div>
});

export default Select;