// libs
import React, {useEffect, useState} from 'react'
import { useParams } from "react-router-dom";
// components
import Navbar from "../components/navbar";
import Loading from "../components/loading";
import Domain404 from "../components/domain404";
import UserInfo from './user-info';
import ThankYouNote from './thankyou-note';
import ReviewForm from './review-form'
import HomePage from './home'
// css
import "../assets/css/style.css";
import "../assets/css/core.css";
// config & functions
import config from "../config/index";
import ajaxCall from "../utils/ajaxcall";
import utils from '../utils';

function Dashboard() {
	const { domain } = useParams();
	const [OrgInfo, setOrgInfo] = useState({});
	const [Token, setToken] = useState(null);
	const [IsLoading, setIsLoading] = useState(true);
	const [DomainNotFound, setDomainNotFound] = useState(false);
	const [LoadMainComponent, setLoadMainComponent] = useState([{
			name: 'HomePage',
			component: HomePage, 
			status: true
		}, {
			name: 'ReviewForm',
			component: ReviewForm, 
			status: false
		}, {
			name: 'UserInfo',
			component: UserInfo, 
			status: false
		}, {
			name: 'ThankYouNote',
			component: ThankYouNote, 
			status: false
		}
	]);
	
	useEffect(() => {
		async function fetchData() {
			let apiData = await ajaxCall.postCall(`${config.api.generic}/account/validate-domain`, {
                domain: domain
            });
            if(apiData && apiData.status && apiData.context.hasOwnProperty('data') && apiData.context.data.length > 0) {
                const tokenVal = await utils.decodeJWT((apiData.context.data[0]));
				if(tokenVal && tokenVal.hasOwnProperty('data')) {
					const data = tokenVal.data;
					let getOrgName = (data.name) ? data.name : null;
					let getLogo = (data.logo) ? data.logo : null;
					let googleSheetId = (data.gSheetId) ? data.gSheetId : null;
					let googleReviewUrl = (data.gReviewUrl) ? data.gReviewUrl : null;
					setOrgInfo({
						name: getOrgName,
						logo: getLogo,
						domain: domain,
						googleSheetId: googleSheetId,
						googleReviewUrl: googleReviewUrl
					});
					setToken(apiData.context.data[0]);
					setIsLoading(false);
					setDomainNotFound(false);
				} else {
					setIsLoading(false);
					setDomainNotFound(true);	
				}
            } else {
				setIsLoading(false);
				setDomainNotFound(true);
			}
		}
		fetchData();
	}, []);

	if(IsLoading) {
		return (<Loading />)
	} else if (DomainNotFound) {
		return (<Domain404 />)
	} else {
		return (
			<>
				<Navbar orgInfo={OrgInfo} setloadmaincomponent={setLoadMainComponent} loadmaincomponent={LoadMainComponent} />
				{LoadMainComponent.map((data, i) => {
					if(data.status) {
						return <data.component 
							key={Math.random()}  
							setloadmaincomponent={setLoadMainComponent} 
							loadmaincomponent={LoadMainComponent} 
							token={Token} 
							orgInfo={OrgInfo}
						/>
					}
				})}
			</>
		)
	}
}

export default Dashboard;