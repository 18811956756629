// libs
import React, { useEffect, useRef, useState, useContext } from 'react';
import { ContextStore } from '../providers/context';
// components 
import Logo from './logo';
// css
// ...
// config & functions 
import utils from '../utils';

function Navbar({orgInfo, setloadmaincomponent, loadmaincomponent}) {
    // context variable
	const globalState = useContext(ContextStore);
	const {dispatch} = globalState;
	// context variable --//

    return (
        <div className="container-fluid">
            <div className='row'>
                <div className='col'>
                    <Logo orgInfo={orgInfo} setloadmaincomponent={setloadmaincomponent} loadmaincomponent={loadmaincomponent} />
                </div>
            </div>
        </div>
    )
}

export default Navbar;
