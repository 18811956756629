// libs
import React, {useState, useEffect, useContext, useRef} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightLong, faLeftLong } from '@fortawesome/free-solid-svg-icons';
// components
import {ContextStore} from '../../providers/context' 
import Breadcrumb from '../../components/breadcrumb';
import Loading from "../../components/loading";
import InputText from '../../components/fields/text';
import InputRadio from '../../components/fields/radio';
import InputCheckbox from '../../components/fields/checkbox';
import Select from '../../components/fields/select';
import TextArea from '../../components/fields/textarea';
// css 
import "../../assets/css/style.css";
import "../../assets/css/core.css";
// config & functions
import ajaxCall from '../../utils/ajaxcall';
import config from '../../config';
import utils from '../../utils';
import isValidForm from '../../utils/validate-form';

let Components = {};
Components['text'] = InputText;
Components['radio'] = InputRadio;
Components['checkbox'] = InputCheckbox;
Components['select'] = Select;
Components['textarea'] = TextArea;

function OrgContact({orgInfo, setloadmaincomponent, loadmaincomponent, token}) {
	// context variable
	const globalState = useContext(ContextStore);
	const {dispatch} = globalState;
	// context variable --//
	const context = globalState.state;
	const contextForm = context.reviewConfig;
	const [FormConfig, setFormConfig] = useState([]);
	const [IsLoading, setIsLoading] = useState(true);
	const [SubmitAction, setSubmitAction] = useState(false);
	const [Error, setError] = useState(false);
	const inputRefs = useRef([]);
	const [ErrorFields, setErrorFields] = useState({});

	const goNext = async (page) => {
		let uc = await utils.updateComponent(loadmaincomponent, {
			name: page,
			status: true
		});
		setloadmaincomponent(uc); // upate component
	}

	const handleActionButton = async (goto) => {
		setSubmitAction(true)
		if(inputRefs && inputRefs.hasOwnProperty('current') && Array.isArray(inputRefs.current) && inputRefs.current.length > 0) {
			let obj = {};
			inputRefs.current.map(ele => {
				obj[ele.name] = ele.value||''
			})
			dispatch({type: 'reviewData', payload: obj});
			// If going back, save data and go back
			if(goto === "HomePage") {
				setTimeout(() => {
					goNext(goto)
				}, 2000);
			} else {
				let vForm = await isValidForm(obj, FormConfig);
				if(vForm && Object.keys(vForm).length === 0) {
					setTimeout(() => {
						goNext(goto)
					}, 2000);
				} else {
					setErrorFields(vForm);
					setSubmitAction(false);
					window.scrollTo(0, 0);
				}
			}
		}
	}

	const LoadFields = () => {
		if(FormConfig && Array.isArray(FormConfig) && FormConfig.length > 0) {
			let AllFields = FormConfig.map((ele,i) => {
				if(ele.enable) {
					ele.index = i;
					ele.errorFields = (Object.keys(ErrorFields).length > 0 && ErrorFields.hasOwnProperty(ele.name)) ? ErrorFields[ele.name] : "";
					let C = Components[ele.field];
					return <C config={ele} ref={inputRefs} key={Math.random()} setloadmaincomponent={setloadmaincomponent} loadmaincomponent={loadmaincomponent} />
				}
			});

			return <>
				{AllFields}
				<div className="mt-1">
					<div className="col-12 mt-2 mb-3 d-flex justify-content-between">
						<button className="btn btn-secondary rounded-0 fs-14rem" type="button" disabled={SubmitAction} onClick={() => handleActionButton('HomePage')}><FontAwesomeIcon icon={faLeftLong} className="dashboard-card-icon" /> {SubmitAction?'Loading':'Back'}</button>
						<button className="btn rwt-secondary-btn text-white rounded-0 fs-14rem" type="button" disabled={SubmitAction} onClick={() => handleActionButton('UserInfo')}>{SubmitAction?'Loading':'Next'} <FontAwesomeIcon icon={faRightLong} className="dashboard-card-icon" /></button>
					</div>
				</div>
			</>
		} else {
			return null;
		}
	}

	const LoadConfig = () => {
		if(IsLoading) {
			return <Loading />
		} else if (Error) {
			return "Something went wrong. Please try again!!"
		} else {
			return <LoadFields />
		}
	}

	useEffect(() => {
		async function fetchData() {
			setIsLoading(true);
			let apiData = await ajaxCall.getCall(`${config.api.generic}/public/account/review-config`, '', token);
            if(apiData.status) {
                let resp = apiData.context.data;
				if(resp && Array.isArray(resp) && resp.length > 0) {
					if(resp[0].hasOwnProperty('review_form_config')) {
						let config = JSON.parse(resp[0].review_form_config);
						dispatch({type: 'reviewConfig', payload: config});
						setFormConfig(config.fields);
						setIsLoading(false);
						setError(false);
					} else {
						setFormConfig({});
						setIsLoading(false);
						setError(true);
					}
				} else {
					setFormConfig({});
					setIsLoading(false);
					setError(true);
				}
            } else {
				setFormConfig({});
				setIsLoading(false);
				setError(true);
			}
		}
		if(contextForm && Object.keys(contextForm).length === 0) {
			fetchData();
		} else {
			setFormConfig(contextForm.fields);
			setIsLoading(false);
			setError(false);
		}
	}, [])

	return (
		<div className="container">
			<div className="row">
				<div className="col">
					<div className="mt-3">
						<h3 className="fs-20rem text-center rwt-txt-dark-blue mb-0">Review Form</h3>
						<div className='d-flex'>
							<Breadcrumb list={[{label: 'Home', url: false}, {label: 'Review Form', url: false}]} />
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-md-10 offset-md-1 shadow p-3 mb-4">
					<div className="py-3">
						{(Object.keys(ErrorFields).length > 0) ? <div className="row">
							<div className="col">
								<div className="text-dark bg-warning p-2 mb-3 fs-13rem">Please update missing mandatory fields</div>
							</div>
						</div>:null}
						<div className="row">
							<LoadConfig />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default OrgContact;