import React from 'react'
import config from '../config';

function Domain404() {
    return (
        <div className="text-center mt-4">
            <img src={`${config.assets.generic}/no-records.png`} />
        </div>
    )
}
export default Domain404;
