// libs
import React from 'react'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// components 
import Dashboard from './pages';
// config & functions 
// ...

const App = () => (
    <Router>
        <Routes>
            <Route exact path="/review/:domain" element={<Dashboard />} />
        </Routes>
    </Router>
)

export default App;
