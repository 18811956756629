// libs
import React, {useEffect, useState, useContext} from 'react';
import { ToastContainer, toast } from 'react-toastify';
// components
import { ContextStore } from '../../providers/context';
import Breadcrumb from "../../components/breadcrumb";
// css 
import "../../assets/css/style.css";
// config & functions
import utils from '../../utils';

function ThankYouNote({orgInfo}) {
    // context variable
	const globalState = useContext(ContextStore);
	const {dispatch} = globalState;
	// context variable --//
	const context = globalState.state;
	const rating = context.rating;
	const reviewConfig = context.reviewConfig;
	const comments = context.reviewData.hasOwnProperty('comments') ? context.reviewData.comments : '';

	const goToGoogleReview = async () => {
		let url = (reviewConfig && reviewConfig.hasOwnProperty('sheet') && reviewConfig.sheet.hasOwnProperty('googleReviewURL')) ? reviewConfig.sheet.googleReviewURL : false;
		if(!url) {
			toast.info("Something went wrong. Please try again!!", {
				className: "fs-13rem"
			})
		} else {
			if(comments) {
				await navigator.clipboard.writeText(`${comments}`);
				toast.success("Comments copied. Redirecting to Google review. Please Enable pop-ups options in your browser", {
					className: "fs-13rem"
				})
			} else {
				toast.success("Redirecting to Google review. Please Enable pop-ups options in your browser", {
					className: "fs-13rem"
				})
			}
			setTimeout(() => {
				window.open(url, "_blank").focus();
			}, 2500);
		}
	}

	const PushGoogle = () => {
		return <>
			<div className="text-center"><img src='/assets/images/delighted.jpg' style={{maxWidth: '80%'}}></img></div>
			<div className="fs-14rem text-center mb-3">We're delighted to receive your {rating}-star rating. We're committed to providing exceptional service and earning more positive feedback like yours.</div>
			<div className="fs-14rem text-center">Would you be willing to share your <strong>review on Google</strong> to help us grow and reach more customers?</div>
			<div className="d-flex justify-content-center align-items-center my-3">
				<button className="btn rwt-secondary-btn btn-sm fs-14rem text-white rounded-0" onClick={goToGoogleReview}>{comments?`Yes, copy and share my comment on Google`:`Review on Google`}</button>
			</div>
		</>
	}

	const CloseRequest = () => {
		return <>
			<div className="text-center"><img src='/assets/images/sad.jpg' style={{maxWidth: '80%'}}></img></div>
			<div className="fs-14rem text-center mb-3">We regret the recent service lapse and the disappointment it caused you. We will take necessary steps to ensure it does not happen again. We value your feedback and will work diligently to improve our services.</div>
		</>
	}

	return (
		<>
			<div className="container">
				<div className="row">
					<div className="col">
						<div className="mt-3">
							<h3 className="fs-20rem text-center rwt-txt-dark-blue mb-0">Thank you for your feedback</h3>
							<div className='d-flex'>
								<Breadcrumb list={[{label: 'Home', url: false}, {label: 'Thank You', url: false}]} />
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-10 offset-md-1 shadow p-3 mb-4">
						<div className="py-3">
                            {(rating>=4)?<PushGoogle />:<CloseRequest />}
						</div>
					</div>
				</div>
			</div>
			<ToastContainer
                position="top-center" 
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
		</>
    )
}

export default ThankYouNote;