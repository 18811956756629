const errorMsg = "This field is required";
const emailErrorMsg = "Invalid Email";
const phoneErrorMsg = "Invalid Phone Number";

const isValidForm = async (formValue, formSchema) => {
    let errorObj = {};
    if(formSchema && Array.isArray(formSchema) && formSchema.length > 0) {
        formSchema.map(ele => {
            if(ele.rules.required) {
                if(formValue.hasOwnProperty(ele.name) && formValue[ele.name] !== "") {
                    if(ele.rules.isEmail) {
                        let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                        if (!formValue[ele.name].match(validRegex)) {
                            errorObj[ele.name] = emailErrorMsg;
                        }
                    } else if (ele.rules.isPhoneNo) {
                        let phoneno = /^\d{10}$/;
                        if(!formValue[ele.name].match(phoneno)) {
                            errorObj[ele.name] = phoneErrorMsg;
                        }
                    } else if (formValue[ele.name].length > ele.rules.maxLength) {
                        errorObj[ele.name] = `Your current character is ${formValue[ele.name].length}. It cannot be more then ${ele.rules.maxLength}`;
                    }
                } else {
                    // if blank, send error message
                    errorObj[ele.name] = errorMsg;
                }
            }
        });
    }
    return errorObj;
}

export default isValidForm;