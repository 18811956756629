// libs
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightLong, faLeftLong } from '@fortawesome/free-solid-svg-icons';
// components
// ...
// css 
// config & functions
import utils from '../../utils';

function Action({config, setloadmaincomponent, loadmaincomponent}) {

    const goNext = async (page) => {
		let uc = await utils.updateComponent(loadmaincomponent, {
			name: page,
			status: true
		});
		setloadmaincomponent(uc); // upate component
	}

    return (
        <div className="mt-1">
            <div className="col-12 mt-2 mb-3 d-flex justify-content-between">
                <button className="btn btn-secondary rounded-0 fs-14rem" type="button" onClick={() => {
                    if(config.buttons[0].action === 'button') {
                        goNext(config.buttons[0].page)
                    } else {
                        config.buttons[0].callback()
                    }
                }}><FontAwesomeIcon icon={faLeftLong} className="dashboard-card-icon" /> {config.buttons[0].label}</button>
                <button className="btn rwt-secondary-btn text-white rounded-0 fs-14rem" type="button" onClick={()=> {
                    if(config.buttons[1].action === 'button') {
                        goNext(config.buttons[1].page)
                    } else {
                        config.buttons[1].callback()
                    }
                }}>{config.buttons[1].label} <FontAwesomeIcon icon={faRightLong} className="dashboard-card-icon" /></button>
            </div>
        </div>
    )
}

export default Action;