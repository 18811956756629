import React, { createContext, useReducer } from 'react'

const initialState = {
	rating: '',
	reviewConfig: {},
	reviewData: {},
	userInfo: {},
}
const ContextStore = createContext(initialState);

const ContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer((state, action) => {
		switch (action.type) {
			case 'rating':
				return { ...state, rating: action.payload }
			case 'reviewConfig':
				return { ...state, reviewConfig: action.payload }
			case 'reviewData':
				return {...state, reviewData: action.payload}
			case 'userInfo':
				return {...state, userInfo: action.payload}
			default:
				throw new Error();
		}
	}, initialState)

	return <ContextStore.Provider value={{ state, dispatch }}>{children}</ContextStore.Provider>
}

export { ContextStore, ContextProvider }
