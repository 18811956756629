// libs
import React, {useState, useEffect, useContext, useRef} from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightLong, faLeftLong } from '@fortawesome/free-solid-svg-icons';
// components
import Breadcrumb from '../../components/breadcrumb';
import {ContextStore} from '../../providers/context'; 
import InputText from '../../components/fields/text';
import InputRadio from '../../components/fields/radio';
import InputCheckbox from '../../components/fields/checkbox';
import Select from '../../components/fields/select';
import TextArea from '../../components/fields/textarea';
import Action from '../../components/fields/action';
// css 
import "../../assets/css/style.css";
import "../../assets/css/core.css";
// config & functions
import config from '../../config';
import utils from '../../utils';
import ajaxCall from '../../utils/ajaxcall';
import isValidForm from '../../utils/validate-form';

let Components = {};
Components['text'] = InputText;
Components['radio'] = InputRadio;
Components['checkbox'] = InputCheckbox;
Components['select'] = Select;
Components['textarea'] = TextArea;
Components['action'] = Action;

function UserInfo({orgInfo, setloadmaincomponent, loadmaincomponent, token}) {
	// context variable
	const globalState = useContext(ContextStore);
	const {dispatch} = globalState;
	// context variable --//
    const context = globalState.state;
    const contextForm = context.reviewConfig;
    const [IsLoading, setIsLoading] = useState(false);
    const [SubmitAction, setSubmitAction] = useState(false);
    const inputRefs = useRef([]);
	const [ErrorFields, setErrorFields] = useState({});

	const fields = [{
		enable: true,
		field: 'text',
		placeholder: 'Name',
		label: 'Name',
		name: 'fname',
		context: 'userInfo',
		rules: {
			required: true,
			maxLength: 32,
		},
        column: 6
	}, {
		enable: true,
		field: 'text',
		placeholder: 'Last Name',
		label: 'Last Name',
		name: 'lname',
		context: 'userInfo',
		rules: {
			required: false,
			maxLength: 32,
		},
        column: 6
	}, {
		enable: true,
		field: 'text',
		placeholder: 'Email',
		label: 'Email',
		name: 'email',
		context: 'userInfo',
		rules: {
			required: false,
			maxLength: 32,
			isEmail: true
		},
        column: 6
	}, {
		enable: true,
		field: 'text',
		placeholder: 'Phone No',
		label: 'Phone No',
		name: 'phoneno',
		context: 'userInfo',
		rules: {
			required: true,
			isPhoneNo: true
		},
        column: 6
	}]

    const goNext = async (page) => {
		let uc = await utils.updateComponent(loadmaincomponent, {
			name: page,
			status: true
		});
		setloadmaincomponent(uc); // upate component
	}

    const handleActionButton = (goto) => {
        setSubmitAction(true)
        // If user goes back, get the data and store in context
		if(inputRefs && inputRefs.hasOwnProperty('current') && Array.isArray(inputRefs.current) && inputRefs.current.length > 0) {
			let obj = {};
			inputRefs.current.map(ele => {
				obj[ele.name] = ele.value||'';
			})
			dispatch({type: 'userInfo', payload: obj});
			setTimeout(() => {
				goNext(goto)
			}, 2000);
		}
	}

    const submit = async () => {
        setIsLoading(true);
        setSubmitAction(true);
        let allReview = {rating: context.rating};
		let obj = {};
        // user contact info...
        if(inputRefs && inputRefs.hasOwnProperty('current') && Array.isArray(inputRefs.current) && inputRefs.current.length > 0) {
			inputRefs.current.map(ele => {
				obj[ele.name] = ele.value||'';
			})
		}
		dispatch({type: 'userInfo', payload: obj});
		let vForm = await isValidForm(obj, fields);
		if(vForm && Object.keys(vForm).length === 0) {
			// merge contact form...
			Object.assign(allReview, obj);
			// merge review data...
			if(contextForm && contextForm.hasOwnProperty('fields') && Array.isArray(contextForm.fields) && contextForm.fields.length > 0) {
				contextForm.fields.map(ele => {
					Object.assign(allReview, {[ele.name]: context.reviewData[ele.name]||''});
				});
			}
			allReview.sheetName = context.reviewConfig.sheet.googleSheetTabName;
			allReview.spreadsheetId = context.reviewConfig.sheet.googleSheetId;
			let apiData = await ajaxCall.postCall(`${config.api.googleSheet}/write`, JSON.parse(JSON.stringify(allReview)), '', {
				"Content-type": "application/json"
			});
			if(apiData.status) {
				setIsLoading(false)
				goNext('ThankYouNote');
			} else {
				toast.info("Something went wrong. Please try again!!", {
					className: "fs-13rem"
				})
				setIsLoading(false);
				setSubmitAction(false);
			}
		} else {
			setErrorFields(vForm);
			setSubmitAction(false);
			setIsLoading(false);
			window.scrollTo(0, 0);
		}
    }

	const LoadFields = () => {
		if(fields && Array.isArray(fields) && fields.length > 0) {
			let AllFields = fields.map((ele,i) => {
				if(ele.enable) {
					ele.index = i;
					ele.errorFields = (Object.keys(ErrorFields).length > 0 && ErrorFields.hasOwnProperty(ele.name)) ? ErrorFields[ele.name] : "";
					let C = Components[ele.field];
					return <C config={ele} ref={inputRefs} key={Math.random()} setloadmaincomponent={setloadmaincomponent} loadmaincomponent={loadmaincomponent} />
				}
			});

			return <>
				{AllFields}
				<div className="mt-1">
					<div className="col-12 mt-2 mb-3 d-flex justify-content-between">
						<button className="btn btn-secondary rounded-0 fs-14rem" type="button" disabled={SubmitAction} onClick={() => handleActionButton('ReviewForm')}><FontAwesomeIcon icon={faLeftLong} className="dashboard-card-icon" /> {SubmitAction?'Loading':'Back'}</button>
						<button className="btn rwt-secondary-btn text-white rounded-0 fs-14rem" disabled={SubmitAction} type="button" onClick={submit}>{SubmitAction?'Loading':'Submit'} <FontAwesomeIcon icon={faRightLong} className="dashboard-card-icon" /></button>
					</div>
				</div>
			</>
		} else {
			return null;
		}
	}

	return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="mt-3">
                            <h3 className="fs-20rem text-center rwt-txt-dark-blue mb-0">User Info</h3>
                            <div className='d-flex'>
                                <Breadcrumb list={[{label: 'Home', url: false}, {label: 'User Info', url: false}]} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-10 offset-md-1 shadow p-3 mb-4">
                        <div className="py-3">
                            <div className="row">
                                <div className="col">
                                    <p className="fs-14rem mb-1 text-center">Kindly share your basic information to facilitate easy communication in case of any queries or concerns.</p>
                                    <p className="fs-13rem fw-bold text-center">NOTE: Your contact information is kept confidential and is not shared with any third parties.</p>
                                </div>
                            </div>
							{(Object.keys(ErrorFields).length > 0) ? <div className="row">
								<div className="col">
									<div className="text-dark bg-warning p-2 mb-3 fs-13rem">Please update missing mandatory fields</div>
								</div>
							</div>:null}
                            <div className="row">
                                <LoadFields />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-center" 
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
	)
}

export default UserInfo;