// libs
import React, {useState, useEffect, useContext} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightLong } from '@fortawesome/free-solid-svg-icons';
// components
import {ContextStore} from '../../providers/context'; 
// css 
import "../../assets/css/style.css";
import "../../assets/css/core.css";
// config & functions
import config from '../../config';
import utils from '../../utils';

function HomePage({orgInfo, setloadmaincomponent, loadmaincomponent, token}) {
	// context variable
	const globalState = useContext(ContextStore);
	const {dispatch} = globalState;
	// context variable --//
    const contextRating = globalState.state.rating;
    const [Rating, setRating] = useState('');

    const goNext = async (page) => {
		let uc = await utils.updateComponent(loadmaincomponent, {
			name: page,
			status: true
		});
		setloadmaincomponent(uc); // upate component
	}

    const submit = () => {
        dispatch({type: 'rating', payload: Rating});
        goNext('ReviewForm');
    }

    const saveRating = (val) => {
        setRating(val)
        window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
    }

    useState(() => {
        setRating(contextRating);
    }, [])

	return (
		<div className="container">
            <div className="row">
				<div className="col-md-8 offset-md-2 ">
					<div className="py-3 mb-5">
                        <div className="text-center py-2"><img src={`${config.assets.images}/${orgInfo.domain}/images/header.jpg`} style={{maxWidth: '80%'}} /></div>
                        <div className="fs-16rem text-center p-3">Your feedback is invaluable to us. Help us enhance our services and provide you with an even better experience by rating us today.</div>
                        <div className="fs-16rem text-center p-3 fw-bold">How would you rate your overall experience with our service?</div>
                        <div className="d-flex justify-content-center">
                            <button className={`btn btn-sm rounded-circle border text-white ${(Rating===1)?`rwt-secondary-btn`:`rwt-primary-btn`} m-1`} style={{width: '45px', height: '45px'}} onClick={()=>saveRating(1)}>1</button>
                            <button className={`btn btn-sm rounded-circle border text-white ${(Rating===2)?`rwt-secondary-btn`:`rwt-primary-btn`} m-1`} style={{width: '45px', height: '45px'}} onClick={()=>saveRating(2)}>2</button>
                            <button className={`btn btn-sm rounded-circle border text-white ${(Rating===3)?`rwt-secondary-btn`:`rwt-primary-btn`} m-1`} style={{width: '45px', height: '45px'}} onClick={()=>saveRating(3)}>3</button>
                            <button className={`btn btn-sm rounded-circle border text-white ${(Rating===4)?`rwt-secondary-btn`:`rwt-primary-btn`} m-1`} style={{width: '45px', height: '45px'}} onClick={()=>saveRating(4)}>4</button>
                            <button className={`btn btn-sm rounded-circle border text-white ${(Rating===5)?`rwt-secondary-btn`:`rwt-primary-btn`} m-1`} style={{width: '45px', height: '45px'}} onClick={()=>saveRating(5)}>5</button>
                        </div>
                        {Rating?<div className="d-flex justify-content-center pt-5 pb-2">
                            <button className="btn btn-lg border rwt-secondary-btn text-white" onClick={submit}>NEXT <FontAwesomeIcon icon={faRightLong} className="dashboard-card-icon" /></button>
                        </div>:null}
                    </div>
                </div>
            </div>
        </div>
	)
}

export default HomePage;