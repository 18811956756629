// libs
import React, {forwardRef, useEffect, useContext} from 'react'
// components
import { ContextStore } from '../../providers/context';
// css 
// config & functions

const InputRadio = forwardRef(({config}, ref) => {
    // context variable
	const globalState = useContext(ContextStore);
	const {dispatch} = globalState;
	// context variable --//

    const contextValue = globalState.state[config.context];
    const defaultVal = contextValue && contextValue.hasOwnProperty(config.name) ? contextValue[config.name] : '';

    const handleOnChange = (val) => {
        let obj = {};
        obj.name = config.name;
        obj.value = val;
        ref.current[config.index] = obj
    }

    const PrintRadio = () => {
        if(config.value && Array.isArray(config.value) && config.value.length > 0) {
            return config.value.map(val => {
                let rn = Math.floor(Math.random() * 1000);
                return <div key={Math.random()} className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" defaultChecked={(defaultVal===val)?true:false} name={config.name} id={`flexRadioDefault${rn}`} value={val} onChange={e=>handleOnChange(e.target.value)} />
                    <label className="form-check-label fs-14rem" htmlFor={`flexRadioDefault${rn}`}>{val}</label>
                </div>
            })
        } else {
            return null;
        }
    }

    useEffect(() => {
        // send to ref onload if available
        if(defaultVal) {
            let obj = {};
            obj.name = config.name;
            obj.value = defaultVal;
            ref.current[config.index] = obj
        }
    }, [])

    return <div className={`col-md-${config.column} mb-3`}>
        <div><label className="form-label fw-bold fs-14rem">{config.label} {(config.rules.required)?<span className="text-danger">*</span>:null}</label></div>
        <PrintRadio />
        {config.errorFields !== "" ? <div className='text-danger fs-13rem'>{config.errorFields}</div>:null}
    </div>
});

export default InputRadio;