// libs
import React, {forwardRef, useContext} from 'react'
// components
import { ContextStore } from '../../providers/context';
// css 
// config & functions

const InputText = forwardRef(({config}, ref) => {
    // context variable
	const globalState = useContext(ContextStore);
	const {dispatch} = globalState;
	// context variable --//
    const contextValue = globalState.state[config.context];
    const defaultVal = contextValue && contextValue.hasOwnProperty(config.name) ? contextValue[config.name] : '';

    return (
        <div className={`col-md-${config.column} mb-3`}>
            <label className="form-label fw-bold fs-14rem">{config.label} {(config.rules.required)?<span className="text-danger">*</span>:null}</label>
            <input type="text" ref={(ele) => ref.current[config.index] = ele} className="form-control" defaultValue={defaultVal} aria-describedby={config.label} name={config.name} required={config.rules.required || false} placeholder={config.placeholder} />
            {config.errorFields !== "" ? <div className='text-danger fs-13rem'>{config.errorFields}</div>:null}
        </div>
    )
});

export default InputText;