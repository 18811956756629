export default {
    api: {
        generic: "https://feedback.api.vyayatatech.com/public",
        // generic: "http://localhost:8000/public",
        googleSheet: "https://gsheets.api.vyayatatech.com"
        // googleSheet: "http://localhost:8080"
    },
    assets: {
        images:"https://storage.googleapis.com/inhouse-feedback-app-public",
        generic:"assets/images"
    },
    secretKey: "123456789",
    pagination: {
        limitPerPage: 15
    }
}