// libs
import React, {forwardRef, useEffect, useContext} from 'react'
// components
import { ContextStore } from '../../providers/context';
// css 
// config & functions

const InputCheckbox = forwardRef(({config}, ref) => {
    // context variable
	const globalState = useContext(ContextStore);
	const {dispatch} = globalState;
	// context variable --//

    const contextValue = globalState.state[config.context];
    const defaultVal = contextValue && contextValue.hasOwnProperty(config.name) ? contextValue[config.name].split(',') : [];

    const handleOnSelect = (val) => {
        const arr = defaultVal;
        const index = arr.indexOf(val);
        let obj = {};
        obj.name = config.name;
        if (index >= 0) {
            arr.splice(index, 1);
            obj.value = arr.toString();
            ref.current[config.index] = obj
        } else {
            arr.push(val);
            obj.value = arr.toString();
            ref.current[config.index] = obj
        }
    }

    const PrintCheckbox = () => {
        if(config.value && Array.isArray(config.value) && config.value.length > 0) {
            return config.value.map(val => {
                let rn = Math.floor(Math.random() * 1000);
                return <div key={Math.random()} className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" name={config.name} defaultValue={val} id={`flexCheckDefault${rn}`} onChange={(e) => {handleOnSelect(String(e.target.value))}} defaultChecked={defaultVal.includes(val)?true:false} />
                    <label className="form-check-label fs-14rem" htmlFor={`flexCheckDefault${rn}`}>{val}</label>
                </div>
            })
        } else {
            return null;
        }
    }

    useEffect(() => {
        // send to ref onload if available
        if(Array.isArray(defaultVal) && defaultVal.length > 0) {
            const arr = defaultVal;
            let obj = {};
            obj.name = config.name;
            obj.value = arr.toString();
            ref.current[config.index] = obj;
        }
    }, [])

    return <div className={`col-md-${config.column} mb-3`}>
        <div><label className="form-label fw-bold fs-14rem">{config.label} {(config.rules.required)?<span className="text-danger">*</span>:null}</label></div>
        <PrintCheckbox />
        {config.errorFields !== "" ? <div className='text-danger fs-13rem'>{config.errorFields}</div>:null}
    </div>
});

export default InputCheckbox;